@import "common/variables";

// Automatically injected Bower dependencies via wiredep (never manually edit this block)
// bower:scss
@import "../../bower_components/bootstrap-sass/assets/stylesheets/_bootstrap.scss";
@import "../../bower_components/font-awesome/scss/font-awesome.scss";
// endbower

@import "common/global";
@import "components/buttons";
@import "components/comments";
@import "components/forms";
@import "components/grid";
@import "components/wp-classes";
@import "layouts/header";
@import "layouts/sidebar";
@import "layouts/footer";
@import "layouts/pages";
@import "layouts/posts";
@import "layouts/tinymce";
@import "layouts/print";

@media (max-width: 767px) {
  .search-field {
    display: none !important;
  }
  input,
  select,
  textarea {
    width: 100%;
  }
  .navbar-nav {
    margin-left: 0;
    width: 100%;
  }
  .header-abs {
    position: relative;
    bottom: 0;
  }
  .navbar-nav .open .dropdown-menu > li > a {
    padding: 5px 15px 5px 45px;
  }

  .dropdown-menu > li > a {
    white-space: normal;
  }
  .page-header {
    margin-top: 60px;
  }
  .wrap {
    float: none;
  }
}
@media (min-width: 768px) {
  .search-field {
    display: block !important;
  }
  button,
  input,
  select,
  textarea {
    width: auto;
  }

  /* dropdown */
  .dropdown-menu {
    margin: 0px auto;
  }
  .dropdown-menu .dropdown-menu {
    left: 100%;
    top: 0px;
  }
  /*  shows the dropdown on hover*/
  .navbar ul.nav li:hover > ul.dropdown-menu {
    display: block;
  }
  /* before and after */
  .navbar .nav > li > .dropdown-menu::before,
  .navbar .nav > li > .dropdown-menu::after {
    display: none;
  }

  .navbar-default .navbar-collapse {
    font-size: 14px;
  }
  .nav > li > a {
    padding: 0px 10px;
  }
  .navbar-nav {
    margin-left: 56px;
  }
  .header-abs {
    bottom: 50px;
    position: absolute;
  }
  .navbar-nav .open .dropdown-menu > li > a {
    padding: 5px 15px 5px 25px;
  }
  .page-header {
    margin-top: 20px;
  }
  .wrap {
    float: left;
  }
}
@media (min-width: 992px) {
  .blank-line {
    height: 70px;
  }
  .navbar-nav {
    margin-left: 43px;
  }
  .navbar-default .navbar-collapse {
    font-size: 20px;
  }
}
@media (min-width: 1200px) {
  .blank-line {
    height: 90px;
  }
  .nav > li > a {
    padding: 0px 25px;
  }
  .navbar-nav {
    margin-left: 33px;
  }
}
