.prim-button{
    font-family:"Fira Sans Book";
    font-size:20px;
    color:#777;
    border:1px solid #eee;
    padding: 13px 40px 7px 10px;
    background-image:url("../images/pfeil-rechts.png");
    background-repeat: no-repeat;
    background-position: 98%;
    margin-bottom:20px;
    float:left;
    width:100%;
}

a.prim-button:hover{
    color: #333;
    text-decoration: none;
    background-color: rgba(253, 237, 131, 0.3);
}

.btn-primary:hover, .comment-form input[type="submit"]:hover {
    color: #FEED83;
    background-color: #fff;
    border: 1px solid #EEE;
}

.btn-primary, .comment-form input[type="submit"] {
    color: #333;
    background-color: #fff;
    border: 1px solid #EEE;
}

.headingContainer .next-a {
    background-image: url("../images/pfeil-unten.png");
    background-repeat: no-repeat;
    background-position: left center;
    height: 25px;
    left: -200px;
    position: absolute;
    top: -35px;
    width: 165px;
    padding-left: 20px;
}
.headingContainer {
    position: relative;
}

.info{
    background-image: url("../images/informationen.jpg");
    background-repeat: no-repeat;
    position:fixed;
    top: 190px;
    right:0;
    width:40px;
    height:40px;
    z-index:999;
}
.suche{
    background-image: url(../images/suche.jpg);
    background-repeat: no-repeat;
    position: fixed;
    top: 140px;
    right: 0;
    width: 40px;
    height: 40px;
    z-index: 999;
}
