.navbar-default .navbar-nav > li > a:focus, .navbar-default .navbar-nav > li > a:hover {
    color: #333;
    background-color: rgba(253,237,131,0.3);
}

.navbar-default .navbar-nav > .active > a, .navbar-default .navbar-nav > .active > a:focus, .navbar-default .navbar-nav > .active > a:hover {
    background-color: rgba(253,237,131,0.3);
}

.navbar-default {
    background-color: #fff;
    border-color: #FEED83;
}

.navbar-default .navbar-nav > .open > a, .navbar-default .navbar-nav > .open > a:focus, .navbar-default .navbar-nav > .open > a:hover, .navbar-default .navbar-nav .open .dropdown-menu > .active > a, .navbar-default .navbar-nav .open .dropdown-menu > .active > a:focus, .navbar-default .navbar-nav .open .dropdown-menu > .active > a:hover {
    background-color: rgba(253,237,131,0.3);
}
.navbar-static-top {
    border-width: 0px 0px 5px;
}

.navbar {max-height: 52px;line-height: 52px;z-index: 997;}

.navbar-default .navbar-collapse, .navbar-default .navbar-form {
    font-family: "Fira Sans Book";
    font-size: 20px;
    background-color:#fff;
}

.navbar-nav {margin-left: 33px;}

.nav > li >a {padding:0 25px;line-height: 47px;}

.dropdown-menu > .active > a, .dropdown-menu > .active > a:focus, .dropdown-menu > .active > a:hover {
    background-color: rgba(253,237,131,0.3);
    color:#333;
}
.dropdown-menu > li > a:focus, .dropdown-menu > li > a:hover {
    background-color: rgba(253,237,131,0.3);
    color: #333;
    background-image: url("../images/pfeil-rechts.png");
    background-repeat: no-repeat;
    background-position: 98% center;
}

.dropdown-menu {padding:0;}

.dropdown-menu > li > a {
    font-size:20px;
    color: #777;
    line-height: 47px;
    padding-right: 30px;
}
.navbar { margin-bottom:15px;}
