@media print {
  a[href]:after {
    content: none !important;
  }
.wrap,.page-header,.col-md-offset-2 {
    margin:0 !important;
}

.header-line,.header-abs{
    position: relative;
    float: left;
}
.header-line{
    top:0;
    height:auto;
}

.logo-a{
    display: block!important;
}
.backstretch { display: none; }

.content-info {
    border-top: 2px solid #000;
}

.prim-button,.fsak-title,h1,h2,h3 {
    border:none;
    margin:0;
    padding:10px 0;
}

.col-md-10{
    width:100%;
}

.col-md-7 {
  width: 100%;
}

#popup,.info,.prim-button,.widget-3,.widget-7{
    display:none
}

.pos-rel {
  position: relative;
  float: left;
  width: 100%;
}

.content ul {
    list-style-image: none;
    list-style-type: square;
}
*{
    font-family: Arial!important;
}

*,
 *:before,
 *:after {
     background: transparent !important;
     color: #000 !important; // Black prints faster: h5bp.com/s
     box-shadow: none !important;
     text-shadow: none !important;
 }

 a,
 a:visited {
     text-decoration: underline;
 }

 a[href]:after {
     content: " (" attr(href) ")";
 }

 abbr[title]:after {
     content: " (" attr(title) ")";
 }

 // Don't show links that are fragment identifiers,
 // or use the `javascript:` pseudo protocol
 a[href^="#"]:after,
 a[href^="javascript:"]:after {
     content: "";
 }

 pre,
 blockquote {
     border: 1px solid #999;
     page-break-inside: avoid;
 }

 thead {
     display: table-header-group; // h5bp.com/t
 }

 tr,
 img {
     page-break-inside: avoid;
 }

 img {
     max-width: 100% !important;
 }

 p,
 h2,
 h3 {
     orphans: 3;
     widows: 3;
 }

 h2,
 h3 {
     page-break-after: avoid;
 }

 // Bootstrap specific changes start

 // Bootstrap components
 .navbar {
     display: none;
 }
 .btn,
 .dropup > .btn {
     > .caret {
         border-top-color: #000 !important;
     }
 }
 .label {
     border: 1px solid #000;
 }

 .table {
     border-collapse: collapse !important;

     td,
     th {
         background-color: #fff !important;
     }
 }
 .table-bordered {
     th,
     td {
         border: 1px solid #ddd !important;
     }
 }


}
