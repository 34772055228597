//Search-Expanded
.form {
    margin: 0 auto;
    position: fixed;
    top: 140px;
    width: 40px;
    right: 0;
}
/** Essentials **/
 .toggle {

    border: medium none;
    color: #333;
    cursor: pointer;
    display: block;
    height: 40px;
    position: absolute;
    right: 0;
    width: 40px;
}
#searchform {
    float: right;
    height: 40px;
    margin: 0;
    opacity: 0;
    padding: 0;
    transition: all 0.2s ease-in-out 0s;
    visibility: hidden;
    width: 10px;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
}
.form:hover #searchform {
    display: block;
    opacity: 1;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    visibility: visible;
    width: 250px;
}
#searchform #s {
    background: none repeat scroll 0 0 #FFEC83;
    border: medium none;
    color: #333;
    float: right;
    outline: none;
    font-size: 12px;
    height: 100%;
    padding: 0 15px;
    width: 100%;
}
#searchform #searchsubmit {
    background: url(../images/suche.jpg) no-repeat scroll center center #FFEC83;
    border: medium none;
    color: #333;
    cursor: pointer;
    display: block;
    height: 40px;
    position: absolute;
    right: 0;
    width: 40px;
}

/*
// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-default;
}
*/
#popup{
    position:fixed;
    top: 135px;
    right:-293px;
    z-index:998;
    width:293px;
}

.popup-details{
    margin:0;
    padding:15px;
}

.popup-details{
    background-color:#fff;
    border-top: 5px solid #FEED83;
}
