section h3, footer section h2{
    font-family: "Fira Sans Book";
    font-size: 20px;
    line-height: 25px;
    color: #333;
    padding:0;
    margin:0;
    border:none;
}

section{margin-top:20px;}

section ul
{
    list-style-type: none;
    padding-left:0;
}
section widget-4 .so-widget-image {
    margin-top: 10px;
}
section .sow-image-container .so-widget-image {
    margin-top: 35px;
}
section.widget-4 .so-widget-image{margin-top:10px;}

section.widget-1 .so-widget-image{
    margin:0;
    width:195px!important;
    position: absolute;
    bottom: 0;
    top:-60px;
    left:0;
    z-index:999;
}

section.widget-4 .so-widget-image,
section.widget-5 .so-widget-image,
section.widget-6 .so-widget-image
{width:65px!important;height:65px;}
