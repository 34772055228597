.direkt-link{
    border: 1px solid #EEE;
    background-image: url("../images/pfeil-rechts.png");
    background-repeat: no-repeat;
    background-position: 98% 98%;
    float: left;
    max-height: 240px;
    max-width: 295px;
    height: 240px;
    width: 100%;
    margin-bottom: 20px;
    overflow: hidden;
    position: relative;
}

.direkt-link h3{
        margin-top:10px;
}

.direkt-title {
    float: left;
    padding-left: 10px;
}
.direkt-a {
    position: absolute;
    bottom: 2%;
    right: 2%;
    height: 20px;
    width: 20px;
}

.white{background-color: #fff;}

.header-line{
  z-index:2;
  top: 30px;
  position:absolute;
  height:90px;
}
.header-info{
      margin-top: 7.5px;
}

.pos-rel{position: relative;}

.content-info{
    background-color:#fff;
    border-top: 5px solid #FEED83;
}

.content{
    background:transparent linear-gradient(to bottom, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0.9) 1%, #FFF 85%) repeat scroll 0% 0%;
    padding-bottom:30px;
}

.content ul {
    list-style-image:url(../images/bullet.png);
    padding:0;
    margin:0;
    padding-left: 12px;
}

.header-abs{
    position: absolute;
    bottom:50px;
    width:100%;
}

.wrap{
    float: left;
    margin-top: -65px;
    position: relative;
    z-index: 3;
}

.content-info{float:left;}

.blank-line{
    height:90px;
    top:30px;
    background-color: #fff;
    position: absolute;
    width:100%;
}

.fsak-title h3{
    margin:0;
    padding:0;
    font-size:15px;
}
.fsak-title {
    border: 1px solid #EEE;
    border: 1px solid #EEE;
    padding: 10px;
}

.fsak-box {
    overflow: hidden;
}

.page-header {
    border:none;
}

.entry-title a {
    color: #777;
}

.popup-details h3{
    margin:0;
}

.logo {
    line-height: 90px;
}

img.attachment-post-thumbnail {
    max-width: 100% !important;
    width: 100%;
}

.header-search .search-form {
    position: absolute;
    right: 75px;
    top: 130px;
    z-index: 999;
}

.header-search .search-submit{
    display:none;
}
