@font-face{
    font-family: 'Fira Sans Light';
    src: url('../fonts/FiraSans-Light.eot');
    src: local('Fira Sans Light'),
         url('../fonts/FiraSans-Light.eot') format('embedded-opentype'),
         url('../fonts/FiraSans-Light.woff') format('woff'),
         url('../fonts/FiraSans-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face{
    font-family: 'Fira Sans Book';
    src: url('../fonts/FiraSans-Book.eot');
    src: local('Fira Sans Book'),
         url('../fonts/FiraSans-Book.eot') format('embedded-opentype'),
         url('../fonts/FiraSans-Book.woff') format('woff'),
         url('../fonts/FiraSans-Book.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face{
    font-family: 'Fira Sans SemiBold';
    src: url('../fonts/FiraSans-SemiBold.eot');
    src: local('Fira Sans SemiBold'),
         url('../fonts/FiraSans-SemiBold.eot') format('embedded-opentype'),
         url('../fonts/FiraSans-SemiBold.woff') format('woff'),
         url('../fonts/FiraSans-SemiBold.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

body{
    font-family:"Fira Sans Book";
    font-size:15px;
    line-height:25px;
    color:#333;
    background-image: url(../images/body-bg.jpg);
    background-repeat: no-repeat;
}

h1{
    font-family:"Fira Sans Light";
    font-size:40px;
    line-height:120%;
    color:#999;
}
h2{
    font-family:"Fira Sans Light";
    font-size:30px;
    line-height:36px;
    color:#999;
    border-bottom:1px solid #eee;
    margin-bottom:25px;
    padding-bottom:7px;
}
h3, .kontakt-title{
    font-family:"Fira Sans Book";
    font-size:20px;
    line-height:25px;
    color:#333;
}
strong,.bold{
    font-weight: 700;
}
a.prim-button:hover {border: 1px solid #eee;}
a.next-a,.entry-title a,.ssba, .ssba a{border-bottom:none!important;}
a{color: #333;}
a.direkt-link{border-bottom:none;}
a.direkt-title:hover{background:none;}
main .content a,footer a{color: #333;border-bottom:1px solid #ccc;}
a:hover,a:focus,a:active{background-color:rgba(253,237,131,.3);color:#333;border:none;text-decoration: none;}

.caret {display:none;}

.fa-facebook{
    position: absolute;
    right: 20px;
    top: 30px;
}
.fa-google-plus{
    position: absolute;
    right: 10px;
    top: 60px;
}
